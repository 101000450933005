document.addEventListener('DOMContentLoaded', function () {
  var mobileElement = document.getElementById('mobile-1');
  var fullpageElement = document.getElementById('section-1');
 
  if (mobileElement !== null && mobileElement.length > 0) {
    function setDimensions() {
      if (window.innerWidth < 440) {
        var mobile1Height = document.getElementById('mobile-1').offsetHeight;
        var rotatingBox = document.getElementById('rotatingBox');
        var gap = 40;
        var screenHeight = window.innerHeight;
        var remainingHeight = screenHeight - mobile1Height - gap;
        rotatingBox.style.height = remainingHeight + 'px';
        
        
      } else {
        var rotatingBox = document.getElementById('rotatingBox');
        rotatingBox.style.height = '100vh';
      }
    }
    window.addEventListener('resize', setDimensions);
  }

  if (fullpageElement !== null) {

    if (window.innerWidth <= 700) {
      var myFullpage = new fullpage('#fullpage', {
        continuousVertical: true
      });
    }
  }

});